<template>
    <b-modal
      v-model="activModelAppointime"
      centered
      title="مواعيد الإشغال"
      id="modal-appointime"
      footer-class="removed"
      cancel-variant="outline-secondary"
      size="xl"
      no-close-on-backdrop
    >
      <validation-observer ref="editdonorsValidation">
        <b-form class="auth-login-form mt-2" @submit.prevent>
          <b-card-text>
            <b-list-group class="rounded">
              <b-list-group-item class="">
                <b-row class="">
                  <b-col> توقيت البداية </b-col>
                  <b-col>توقيت النهاية</b-col>
                  <b-col> التاريخ </b-col>
                  <b-col> </b-col>
                </b-row>
              </b-list-group-item>
                <!-- <alert v-if="appointime.length == 0  && !loading"></alert> -->
              <div
                class="text-info text-center px-5"
                style="margin: 30px"
                v-if="loading"
              >
                <b-spinner variant="info"></b-spinner> جاري التحميل...
              </div>
              <b-list-group-item v-else v-for="item in appointime" :key="item.id">
                <b-row v-if="id_donors == item.id">
                  <b-col cols="3">
                    <form-timepicker
                      label="توقيت البداية"
                      v-model="from_time"
                      placeholder= "HH-MM"
                      rules= "required"
                    />
                  </b-col>
                  <b-col>
                    <form-timepicker
                    label="توقيت النهاية"
                    v-model="to_time"
                    placeholder= "HH-MM"
                    rules= "required"
                    />
                  </b-col>
                  <b-col>
                    <form-datepicker
                    label="التاريخ"
                    v-model="start_date"
                    placeholder= "HH-MM"
                    rules= "required"
                    />
                  </b-col>
  
                  <b-col
                    class="justify-content-end d-flex pt-50 align-items-center"
                  >
                    <b-button
                      @click="
                        isAdding = false;
                        id_donors = null;
                      "
                      variant="outline-secondary"
                      class=""
                    >
                      <feather-icon icon="XIcon" />
                      إلغاء
                    </b-button>
                    <save-btn
                      :loading="loadingUpdate"
                      class="ml-1"
                      variant="primary"
                      style="height: 40px"
                      @click="formSubmitEdit(item.id)"
                      type="submit"
                    >
                    </save-btn>
                  </b-col>
                </b-row>
  
                <b-row class="align-items-center" v-else>
                  <b-col>{{ $moment(item.from_time,['h:mm']).locale('ar').format('hh:mm A') }}</b-col>
                  <b-col>
                    {{ $moment(item.to_time,['h:mm']).locale('ar').format('hh:mm A')  }}
                  </b-col>
                  <b-col> {{ item.start_date }}</b-col>
                  <b-col class="justify-content-end d-flex">
                    <b-button
                      @click="
                        id_donors = item.id;
                        from_time = item.from_time;
                        to_time = item.to_time;
                        start_date = item.start_date;
                        isAdding = false;
                      "
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-spinner
                      v-if="deleteLoading == item.id"
                      small
                      variant="danger"
                      class="mt-1"
                      style="margin-left: 12px; margin-right: 12px"
                    ></b-spinner>
                    <b-button
                      v-else
                      @click="
                        deleteLoading = item.id;
                        deleteAppointments({
                          id: item.id,
                          appontime_id:appointime_id
                        }).then(() => {
                          deleteLoading = null;
                        });
                      "
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item v-if="isAdding">
                <h5 class="mb-2 text-primary">إضافة</h5>
                <b-row>
                  <b-col cols="3">
                    <form-timepicker
                    label="توقيت البداية"
                    v-model="from_time"
                    placeholder= "HH-MM"
                    rules= "required"
                    />
                          </b-col>
                  <b-col>
                    <form-timepicker
                    label="توقيت النهاية"
                    v-model="to_time"
                    placeholder= "HH-MM"
                    rules= "required"
                    />
                  </b-col>
                  <b-col>
                    <form-datepicker
                    label="التاريخ"
                    v-model="start_date"
                    placeholder= "HH-MM"
                    rules= "required"
                    />
                  </b-col>
  
                  <b-col class="justify-content-end d-flex pt-50 align-items-center">
                    <b-button
                      @click="isAdding = false"
                      variant="outline-secondary"
                      class=""
                    >
                      <feather-icon icon="XIcon" />
                      إلغاء
                    </b-button>
                    <save-btn
                      :loading="loadingCreate"
                      class="ml-1"
                      variant="primary"
                      style="height: 40px"
                      @click="formSubmitCreate()"
                      type="submit"
                    >
                    </save-btn>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card-text>
  
          <b-row class="px-1 d-flex justify-content-end">
            <b-button @click="unFilter()" variant="primary">
              <feather-icon icon="PlusIcon" />
              <span> إضافة </span>
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </template>
  <script>
  import saveBtn from "@/components/loader-btn";
  import {
    BModal,
    BForm,
    BButton,
    BFormGroup,
    BCol,
    BRow,
    BCardText,
    BListGroup,
    BListGroupItem,
    BSpinner,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapActions, mapGetters } from "vuex";
  import Alert from "@/components/alert.vue";
  import FormDatepicker from "@/components/form-datepicker";
  import FormTimepicker from "@/components/form-time/index.vue"

  export default {
    props: {
      value: Boolean,
      appointime_id: Number,
      page:Number
    },
    components: {
      BSpinner,
      BListGroupItem,
      BListGroup,
      BModal,
      BForm,
      ValidationProvider,
      ValidationObserver,
      BButton,
      BFormGroup,
      BCol,
      BRow,
      BCardText,
      saveBtn,
      Alert,
      FormDatepicker,
      FormTimepicker
    },
    data: () => {
      return {
        isAdding: false,
        deleteLoading: false,
        id_donors: null,
        id_roul: null,
        start_date: "",
        from_time:"",
        to_time:"",
      };
    },
    methods: {
      formSubmitEdit(id) {
        this.$refs.editdonorsValidation.validate().then(async (success) => {
          if (success) {
            this.updateAppointment(id);
          }
        });
      },
  
      formSubmitCreate() {
        this.$refs.editdonorsValidation.validate().then(async (success) => {
          if (success) {
            this.addAppointment();
          }
        });
      },
      ...mapActions("manageCenter/hallsOccupancy", [
        "createAppointments",
        "updateAppointments",
        "deleteAppointments",
        "getAppointments"

      ]),
  
      addAppointment() {
        this.createAppointments({
          id:this.appointime_id,
          data: {
            hall_occupancy_id: this.appointime_id,
            from_time:this.from_time,
            to_time:this.to_time,
            start_date:this.start_date
          },
        }).then((res) => {
          this.isAdding = false;
        });
      },
  
      updateAppointment(id) {
        this.updateAppointments({
          id: id,
          data: {
            hall_occupancy_id: this.appointime_id,
            from_time:this.from_time,
            to_time:this.to_time,
            start_date:this.start_date
          },
        });
      },
  
      unFilter() {
        this.isAdding = true;
        this.start_date = "";
        this.from_time = "";
        this.to_time = "";
        this.id_donors = null;
      },
    },
    watch: {
      appointime_id(val) {
        this.getAppointments({ id: val });
      },
    },
  
    computed: {
      ...mapGetters("manageCenter/hallsOccupancy", [
        "appointime",
        "loading",
        "loadingCreate",
        "loadingUpdate",
      ]),
      activModelAppointime: {
        set(val) {
          this.$emit("input", val);
        },
        get() {
          return this.value;
        },
      },
    },
    created() {
      // this.getAppointments()
    },
  };
  </script>
